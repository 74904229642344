


































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { MARITAL_STATUS, EDUCATION, INDUSTRY, POSITION } from "@/constants";
import { TabLayout } from "@/layouts";
import { UserInfo } from "@/types";
import { getRoles, formatPhoneNumberUtil } from "@/utils/common";
import { Loader, Dropdown, CustomInput } from "@/components";

const userModule = namespace("user");
const configModule = namespace("config");

Vue.use(Vuelidate);

@Component({
  components: {
    TabLayout,
    Loader,
    Dropdown,
    CustomInput,
  },
  validations: {
    user: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      phone: {
        required,
      },
    },
  },
})
export default class AccountProfile extends Vue {
  @userModule.Getter("currentUserInfo") currentUserInfo!: UserInfo;
  @userModule.Getter("userInfoExists") userInfoExists!: boolean;
  @configModule.Getter("cities") cities!: Array<any>;
  @configModule.Getter("provinces") provinces!: Array<any>;
  @userModule.Action("updateUserInfo") updateUserInfo!: (
    userInfo: UserInfo
  ) => void;
  @userModule.Action("getUserInfo") getUserInfo!: () => void;

  user = {} as UserInfo;
  loading = false;

  get saveButtonText(): string {
    return this.loading ? "Saving User Info" : "Save";
  }

  get listOptions() {
    return {
      maritalStatus: MARITAL_STATUS,
      education: EDUCATION,
      industry: INDUSTRY,
      position: POSITION,
    };
  }

  get userRole(): string {
    let label = "";
    const roles = getRoles();

    for (const [key, value] of Object.entries(roles)) {
      if (value === this.user.role) {
        label = key;
      }
    }

    return label;
  }

  get getCities(): Array<any> {
    // return ["Manila", "Quezon"];
    if (!this.provinces.length) {
      return [];
    }

    const province = this.provinces.find((province) => {
      return province.name === this.user.address!.province;
    });

    if (!province) {
      return [];
    }

    const city = this.cities.find((city) => {
      return province.id === city.provinceId;
    });

    const list = city.list.map((city: any) => city.name);

    return list;
  }

  get getProvinces(): Array<any> {
    if (!this.provinces.length) {
      return [];
    }

    return this.provinces.map((province) => {
      return province.name;
    });
  }

  get cityPlaceholder() {
    return !this.user.address!.province ? "Please select a province first" : "";
  }

  onProvinceChange(value: any) {
    this.user.address!.province = value;
  }

  resetUserInfo() {
    this.user = this._constructUserInfo(this.currentUserInfo);
  }

  keyupOfficeNumber() {
    this.user.officeNumber = formatPhoneNumberUtil(
      this.user.officeNumber as string
    );
  }

  keyupHomeNumber() {
    this.user.homeNumber = formatPhoneNumberUtil(
      this.user.homeNumber as string
    );
  }

  keyupPhoneNumber() {
    this.user.phone = formatPhoneNumberUtil(this.user.phone as string);
  }

  async onSubmit(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.loading = true;

    try {
      const payload = this._constructUserInfo(this.user);
      await this.updateUserInfo(payload);
      await this.getUserInfo();
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  _constructUserInfo(data: UserInfo): UserInfo {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.role,
      email: data.email,
      phone: data.phone,
      maritalStatus: data.maritalStatus,
      education: data.education,
      industry: data.industry,
      position: data.position,
      employerName: data.employerName,
      officeNumber: data.officeNumber,
      homeNumber: data.homeNumber,
      address: {
        line1: data.address?.line1,
        line2: data.address?.line2,
        province: data.address?.province,
        city: data.address?.city,
        district: data.address?.district,
        zipCode: data.address?.zipCode,
      },
    };
  }

  @Watch("userInfoExists", { immediate: true })
  watchUserInfoExists(userInfoExists: boolean) {
    if (userInfoExists) {
      this.user = this._constructUserInfo(this.currentUserInfo);
    }
  }
}
